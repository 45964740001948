import React, { useContext } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.sass"
import profilePic from "../../static/imgs/profile.jpg"
import {
  GlobalStateContext,
} from "../context/GlobalContextProvider"

function IndexPage({ location }) {
  const siteTitle = "Gatsby Starter Personal Website"
  const state = useContext(GlobalStateContext)
  const { home } = state.language

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <section className="section-about">
        <div className="wrapper fullHeight" data-grid="center spacing">
          <div data-cell="1of3">
            <div className="container-pic">
              <img src={profilePic} alt="" />
            </div>
          </div>
          <div data-cell="2of3">
            <h1>{home.title}</h1>
            {home.text.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
            <div data-grid="small-spacing">
              {home.btns.map((item, index) => (
                <Link data-cell="shrink" to={item.link} key={index} data-btn>
                  {item.text}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-contact">
            <h1>Swapi - Star Wars Character API list!</h1>
            <section data-grid="spacing">
              <div data-cell="1of4 shrink">
                <select name="filter" id="filter" onChange={(e)=>setParam(e.target.value)}>
                  <option value="people">People</option>
                  <option value="planets">Planets</option>
                  <option value="starships">Starships</option>
                </select>
              </div>
            </section>
            <section data-grid="spacing cols-4">
              {result && result.map(item=>(
                <div data-cell="1of4 shrink" className="card">
                  <h6>{item.name}</h6>
                  {item.gender ? 
                    <ul>
                      <li>Gender: {item.gender}</li>
                      <li>Height: {item.height}</li>
                      <li>Hair Color: {item.hair_color}</li>
                    </ul>
                    : item.climate ? 
                        <ul>
                          <li>Climate: {item.climate}</li>
                          <li>Gravity: {item.gravity}</li>
                          <li>Terrain: {item.terrain}</li>
                        </ul>
                      :
                        <ul>
                          <li>Model: {item.model}</li>
                          <li>Manufacturer: {item.manufacturer}</li>
                          <li>Crew: {item.crew}</li>
                        </ul>
                  }
                </div>
              ))}
            </section>
        </section> */}
    </Layout>
  )
}

export default IndexPage
